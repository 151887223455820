import { Component, Vue, Watch } from "vue-property-decorator";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfContent from "@/vue/domain/content/df-content";
import moment from "moment";
import DfStore from "@/vue/domain/store/df-store";
import DfProduct from "@/vue/domain/product/df-product";
import DfPromotion from "@/vue/domain/promotion/df-promotion";

const DfCardProductComponent = () => Utils.externalComponent2("df-card-product");
const DfSectionLinksComponent = () => Utils.externalComponent2("df-section-links");
const DfStoreCardComponent = () => Utils.externalComponent2("df-store-card");
const DfHeaderMobileComponent = () => Utils.externalComponent2("df-header-mobile");

@Component({
  components: {
    DfHeaderMobile: DfHeaderMobileComponent,
    DfFooter: () => Utils.externalComponent2("df-footer"),
    DfStoreCard: DfStoreCardComponent,
    DfSectionLinks: DfSectionLinksComponent,
    DfCardProduct: DfCardProductComponent,
  },
  filters: {
    dateFormat(value: Date | string): string {
      return value != null ? moment(value, Utils.ISO_8601_STRING_PATTERN).format(Utils.DATE_STRING_PATTER) : "";
    },
  },
})
export default class DfPageSpecialComponent extends Vue {
  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get currentPromotion(): DfPromotion {
    return this.$store.getters.currentPromotion;
  }
  
  get special(): DfContent {
    return this.specials.length ? this.specials.find((special: DfContent) => special.alias === this.$route.params.specialAlias) : null;
  }

  get specials(): Array<DfContent> {
    const storeSpecials: Array<DfContent> = this.$store.getters.storeContents.filter((content: DfContent) => content.type.code === "SPC");
    const brandSpecials: Array<DfContent> = this.$store.getters.brandContents.filter((content: DfContent) => content.type.code === "SPC");
    const promotionSpecials: Array<DfContent> = this.$store.getters.promotionContents.filter((content: DfContent) => content.type.code === "SPC");
    const news: Array<DfContent> = [...storeSpecials, ...brandSpecials, ...promotionSpecials].sort((contentA, contentB) => (contentA.priority <= contentB.priority ? 1 : -1));
    return news;
  }

  get specialBackgroundUrl(): string {
    const backgroundImagePageHeader = Utils.getPropertyImageUrl(this.special, Utils.PROPERTY_BACKGROUND_IMAGE_PAGE_HEADER, null);
    return backgroundImagePageHeader ? backgroundImagePageHeader : Utils.getPropertyImageUrl(this.special, Utils.PROPERTY_BACKGROUND_IMAGE, null);
  }

  get specialBackgroundColor(): string {
    return Utils.getPropertyValue(this.special, Utils.PROPERTY_BACKGROUND_COLOR, "STRING");
  }

  get productsSpecial(): Array<DfProduct> {
    const productsIdentifier: Array<string> = this.special && this.special.products ? this.special.products : [];
    return productsIdentifier.map((productIdentifier: string) => this.cachedProducts.find((product: DfProduct) => product.uniqueId === productIdentifier)).filter((product: DfProduct) => product);
  }

  get cachedProducts(): Array<DfProduct> {
    return this.$store.getters.cachedProducts;
  }

  get promotionAlias(): string {
    return this.currentPromotion.alias;
  }

  get showBreadcrumb(): boolean {
    return Utils.showBreadcrumb();
  }

  get setBackgroundImageSizeContain(): boolean {
    return Utils.getPropertyValue(this.special, Utils.PROPERTY_BACKGROUND_IMAGE_PAGE_HEADER_SIZE_CONTAIN, "BOOLEAN", false);
  }

  get specialPreviewStyle(): any {
    const style: any = {};
    const specialPreview = this.specialBackgroundUrl;
    const specialBackgroundColor = this.specialBackgroundColor;

    if (specialPreview) {
      style.backgroundImage = `url('${specialPreview}')`;
    }

    if (specialBackgroundColor) {
      style.backgroundColor = `${specialBackgroundColor}`;
    }

    if (this.setBackgroundImageSizeContain) {
      style.backgroundSize = `contain`;
    }

    return style;
  }

  @Watch("special", { immediate: true })
  specialChange() {
    document.title = this.special ? `${this.special.name} | ${Utils.APP_NAME}` : "";
    if (this.special) {
      this.loadProductsList();
    }
  }

  private loadProductsList() {
    const productsSpecialUniqueId = this.productsSpecial.map((productSpecial) => productSpecial.uniqueId);
    const productsUniqueId: Array<string> = this.special && this.special.products ? this.special.products.filter((productUniqueId) => !productsSpecialUniqueId.includes(productUniqueId)) : [];
    if (productsUniqueId.length > 0 && productsUniqueId.length !== this.productsSpecial.length) {
      this.$root.$emit("loadCachedProducts", productsUniqueId, 10, this.loadProductsList);
    }
  }
}
